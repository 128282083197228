<template>
  <div class="container">
    <div class="header-tab">
      <div class="content">
        <div class="tabs">
          <div class="item" :class="type==item.id?'active':''" v-for="(item,index) in headTabs" @click="onTab(item.id)">
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="list">
        <div class="item" v-for="(item,index) in teacherList" @click="openBlank('ExpertDetail',item.teacherId)">
          <img class="item-img" :src="item.image" alt="">
          <div class="info">
            <div class="btn-right">
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="name">{{item.name}}</div>
            <div class="college">
              <div class="txtbox">
                <span class="txt" v-for="(txt,j) in item.positionalTitles" :key="'txt'+j">{{txt}}</span>
              </div>
              <!-- <div class="post">{{item.name}}</div> -->
            </div>
            <div class="intro">
              {{item.description}}
            </div>
          </div>
        </div>
      </div>
      <div class="paging">
				<el-pagination 
				background 
				:current-page="page" 
				@current-change="onPage"
				hide-on-single-page
				layout="prev, pager, next" 
				:page-count="totalPage">
				</el-pagination>
			</div>
			<el-empty description="暂无数据" v-if="!teacherList.length"></el-empty>
    </div>
    
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      page:1,
      totalPage:0,
      type:4,
      teacherList:[],
      headTabs:[{id:4,name:'编委会委员'},{id:1,name:'专家委员会'},{id:2,name:'专家顾问'},{id:3,name:'讲师'}],
    };
  },
  created() {
    this.getTeacherList();
  },
  methods: {
    onPage(page){
      this.page=page;
      this.getTeacherList();
    },
    onTab(i){
      this.type=i;
      this.page=1;
      this.getTeacherList()
    },
    getTeacherList() {
      const data={
        page:this.page,
        type:this.type
      }
      this.$http.post('/api/web/teacher/getList',data)
        .then((res) => {
          this.teacherList = res.data.teacherList
          this.totalPage=res.data.totalPage;
        })
    },
    openBlank(name,id){
      let routeUrl = this.$router.resolve({
        name,
        query:{id}
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>

<style lang='less' scoped>
.container{
  min-height: calc(100vh - 410px);
  padding-bottom: 40px;
  background: #f7f7f7;
  .content{
    width: 1300px;
    margin: 0 auto;
  }
  .header-tab{
    border-top: 1px solid #f0f4f6;
    height: 120px;
    background: #fff;
    .tabs{
      display: flex;
      align-items: center;
      height: 120px;
      .item{
        min-width: 100px;
        height: 46px;
        line-height: 46px;
        padding:0 25px;
        border: 1px solid transparent;
        border-radius: 23px;
        font-size: 14px;
        color: #666666;
        margin-right: 10px;
        cursor: pointer;
        &.active{
          font-weight: 700;
          color: #e83d3a;
          border: 1px solid #e83d3a;
        }
      }
    }
  }
  .list{
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    .item{
      width: calc(50% - 20px);
      margin-bottom: 40px;
      display: flex;
      background: #fff;
      padding: 5px;
      border-radius: 12px;
      &:nth-child(2n){
        margin-left: 40px;
      }
      .item-img{
        width: 188px;
        height: 260px;
        border-radius: 10px;
        cursor: pointer;
      }
      .info{
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        cursor: pointer;
        margin: 0 35px;
        padding-top: 24px;
        flex: 1;
        
        .btn-right{
          position: absolute;
          right: 0;
          top: 40px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #d71a18;
          display: flex;
          justify-content: center;
          align-items: center;
          .el-icon-arrow-right{
            font-weight: 700;
            color: #fff;
          }
        }
        .name{
          font-weight: 700;
          font-size: 20px;
          line-height: 1;
          margin-bottom: 20px;
          color: #333;
        }
        .college{
          position: relative;
          display: flex;
          flex-wrap: wrap;
          max-height: 46px;
          width: 330px;
          color: #666;
          font-size: 16px;
          // padding-bottom: 20px;
          // border-bottom: 1px solid #efefef;
          text-align: left;
         
          &::before{
            display: block;
            content: '';
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 1px;
            background: #efefef;
          }
          .txtbox{
 display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          }
          .txt{
            margin-right: 15px;
            text-align: left;
            line-height: 1.5;
          }
          .post{
            margin-left: 15px;
          }
        }
        .intro{
          font-size: 14px;
          color: #999;
          line-height: 2;
          padding-top: 22px;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          height: 130px;
          overflow: hidden;
        }
      }
    }
  }
  /deep/.paging {
      width: 100%;
      margin-top: 20px;
      .el-pager li {
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        background-color: white;
      }
      .el-pager li:not(.disabled).active {
        background-color: #d71a18;
        color: #fff;
      }
      .btn-next,
      .btn-prev {
        background-color: white;
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
      }
    }
}
</style>
